import request from '@/utils/request'


// 查询明星分类列表
export function listCategory(query) {
  return request({
    url: '/star/category/list',
    method: 'get',
    params: query
  })
}

// 查询明星分类分页
export function pageCategory(query) {
  return request({
    url: '/star/category/page',
    method: 'get',
    params: query
  })
}

// 查询明星分类详细
export function getCategory(data) {
  return request({
    url: '/star/category/detail',
    method: 'get',
    params: data
  })
}

// 新增明星分类
export function addCategory(data) {
  return request({
    url: '/star/category/add',
    method: 'post',
    data: data
  })
}

// 修改明星分类
export function updateCategory(data) {
  return request({
    url: '/star/category/edit',
    method: 'post',
    data: data
  })
}

// 修改明星分类
export function updateSort(data) {
  return request({
    url: '/star/category/sort',
    method: 'post',
    data: data
  })
}

// 删除明星分类
export function delCategory(data) {
  return request({
    url: '/star/category/delete',
    method: 'post',
    data: data
  })
}
